import React from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { Facebook, Instagram, YouTube, Twitter } from "./social"
import HuFlag from "./hu-flag"
import EnFlag from "./en-flag"
import DeFlag from "./de-flag"

const Footer = () => {
  return (
    <footer>
      <Navbar bg="light" expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            <Nav.Link href="/hirek/">Hírek</Nav.Link>
            <Nav.Link href="/rolunk/">Rólunk</Nav.Link>
            <Nav.Link href="/tudastar/">Tudástár</Nav.Link>
            <Nav.Link href="/jelentkezes/">Jelentkezés</Nav.Link>
            <Nav.Link href="/gyik/">GYIK</Nav.Link>
            <Nav.Link href="/elerhetoseg/">Elérhetőségek</Nav.Link>
            <Nav.Link href="/tamogatas/">Támogatás</Nav.Link>
            <Nav.Link href="/atlathatosag/">Átláthatóság</Nav.Link>
            <Nav.Link href="/"><HuFlag/></Nav.Link>
            <Nav.Link href="/en/"><EnFlag/></Nav.Link>
            <Nav.Link href="/de/"><DeFlag/></Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <Container>
              <Row>
                <Col>
                  <Facebook />
                </Col>
                <Col>
                  <Instagram />
                </Col>
                <Col>
                  <YouTube />
                </Col>
                <Col>
                  <Twitter />
                </Col>
              </Row>
            </Container>
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    </footer>
  )
}
export default Footer
