import React from "react"
import { FaFacebook, FaYoutube, FaInstagram, FaTwitter } from "react-icons/fa"

const Facebook = ({ props }) => (
  <a
    {...props}
    aria-label="Facebook"
    href="https://www.facebook.com/AURA-Segítő-Kutya-Alapítvány-adószám-18995736-1-09-164164377025485"
  >
    <FaFacebook size="1.5em" />
  </a>
)

const Instagram = ({ props }) => (
  <a {...props} aria-label="Instagram" href="https://instagram.com/aurasegitokutya">
    <FaInstagram size="1.5em" />
  </a>
)

const YouTube = ({ props }) => (
  <a {...props} aria-label="YouTube" href="https://youtube.com/channel/UClGTFeO7F-lAkJoPABwcm5Q">
    <FaYoutube size="1.5em" />
  </a>
)

const Twitter = ({ props }) => (
  <a {...props} aria-label="Twitter" href="https://twitter.com/AuraSegitokutya">
    <FaTwitter size="1.5em" />
  </a>
)

export { Facebook, Instagram, YouTube, Twitter }
